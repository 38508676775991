<template>
  <div class="contentWrap">
    <van-popup v-model="bankSelect" position="bottom">
      <van-picker
        show-toolbar
        :columns="cardList"
        @confirm="onConfirm"
        @cancel="bankSelect = false"
      >
        <template #option="item">
          <div>{{ item.bank_name }} - {{ item.bank_no }}</div>
        </template>
      </van-picker>
    </van-popup>
    <!-- dialog -->

    <van-dialog
      :before-close="setErrorBefore"
      v-model="orderErrorModal"
      show-cancel-button
      title="申请取消"
    >
      <van-form>
        <van-field name="uploader" label="文件上传">
          <template #input>
            <van-uploader
              :after-read="beforeUpload"
              :max-size="8000 * 1024"
              @oversize="onOversize"
              v-model="errorFiles"
              :max-count="3"
            />
          </template>
        </van-field>
        <van-field
          v-model="note"
          rows="2"
          autosize
          label="原因"
          type="textarea"
          placeholder="请输入原因"
        />
      </van-form>
    </van-dialog>
    <van-dialog
      :before-close="setOrderBefore"
      v-model="setOrderModal"
      show-cancel-button
      title="确认提交"
    >
    </van-dialog>
    <!-- dialog -->
    <!-- top -->
    <van-sticky style="width: 100vw">
      <van-nav-bar title="订单信息" left-arrow @click-left="goBack">
      </van-nav-bar>
    </van-sticky>
    <!-- top -->
    <div class="wrap orderContentWrap">
      <van-cell-group inset style="width: 100%; margin-bottom: 10px">
        <div
          style="display: flex; justify-content: center"
          v-if="currentOrder.bank_name == '支付宝扫码' && currentOrder.bank_no"
        >
          <vue-qrcode :value="currentOrder.bank_no" :width="250" />
        </div>
        <van-cell
          :title="'订单号'"
          :label="currentOrder.order_id"
          v-clipboard:copy="
            $returnBuySel('进货') + ': ' + currentOrder.order_id
          "
          v-clipboard:success="onCopy"
        >
          <template #right-icon>
            <van-button
              size="normal"
              block
              plain
              style="
                background-color: #4065f0;
                width: 45px;
                color: white;
                height: 22px;
                font-size: 12px;
                white-space: nowrap;
                border-radius: 5px;
              "
              >複製</van-button
            >
          </template>
        </van-cell>
      </van-cell-group>
      <van-cell-group
        inset
        style="width: 100%; margin-bottom: 10px"
        v-if="currentOrder.bank_name == '抖音红包'"
      >
        <van-cell
          :title="'口令码'"
          :label="currentOrder.bank_branch"
          v-clipboard:copy="currentOrder.bank_branch"
          v-clipboard:success="onCopy"
        >
          <template #right-icon>
            <van-button
              size="normal"
              block
              plain
              style="
                background-color: #4065f0;
                width: 45px;
                color: white;
                height: 22px;
                font-size: 12px;
                white-space: nowrap;
                border-radius: 5px;
              "
              >複製</van-button
            >
          </template>
        </van-cell>
      </van-cell-group>
      <van-cell-group inset style="width: 100%; margin-bottom: 10px">
        <van-cell title="日期" :value="currentOrder.created_at" />
      </van-cell-group>
      <van-cell-group inset style="width: 100%; margin-bottom: 10px">
        <van-cell
          title="应付金额"
          v-clipboard:copy="String(currentOrder.amount)"
          v-clipboard:success="onCopy"
        >
          <template #default>
            <p style="margin-right: 5px">
              {{ currentOrder.amount }}
            </p>
          </template>
          <template #right-icon>
            <van-button
              size="normal"
              block
              plain
              style="
                background-color: #4065f0;
                width: 45px;
                color: white;
                height: 22px;
                font-size: 12px;
                white-space: nowrap;
                border-radius: 5px;
              "
              >複製</van-button
            >
          </template>
        </van-cell>
      </van-cell-group>
      <van-cell-group inset style="width: 100%; margin-bottom: 10px">
        <van-cell title="订单状态" :value="status[currentOrder.status]" />
      </van-cell-group>

      <van-cell-group inset style="width: 100%; margin-bottom: 10px">
        <van-cell
          title="户名"
          v-clipboard:copy="currentOrder.bank_owner"
          v-clipboard:success="onCopy"
        >
          <template #default>
            <p style="margin-right: 5px">
              {{ currentOrder.bank_owner }}
            </p>
          </template>
          <template #right-icon>
            <van-button
              size="normal"
              block
              plain
              style="
                background-color: #4065f0;
                width: 45px;
                color: white;
                height: 22px;
                font-size: 12px;
                white-space: nowrap;
                border-radius: 5px;
              "
              >複製</van-button
            >
          </template>
        </van-cell>
      </van-cell-group>
      <van-cell-group inset style="width: 100%; margin-bottom: 10px">
        <van-cell
          title="银行"
          :value="currentOrder.bank_name"
          v-clipboard:copy="currentOrder.bank_name"
          v-clipboard:success="onCopy"
        >
          <template #default>
            <p style="margin-right: 5px">
              {{ currentOrder.bank_name }}
            </p>
          </template>
          <template #right-icon>
            <van-button
              size="normal"
              block
              plain
              style="
                background-color: #4065f0;
                width: 45px;
                color: white;
                height: 22px;
                font-size: 12px;
                white-space: nowrap;
                border-radius: 5px;
              "
              >複製</van-button
            >
          </template>
        </van-cell>
      </van-cell-group>
      <van-cell-group
        inset
        style="width: 100%; margin-bottom: 10px"
        v-if="currentOrder.bank_name != '支付宝扫码'"
      >
        <van-cell
          title="账号"
          :value="currentOrder.bank_no"
          v-clipboard:copy="currentOrder.bank_no"
          v-clipboard:success="onCopy"
        >
          <template #default>
            <p style="margin-right: 5px">
              {{ currentOrder.bank_no }}
            </p>
          </template>
          <template #right-icon>
            <van-button
              size="normal"
              block
              plain
              style="
                background-color: #4065f0;
                width: 45px;
                color: white;
                height: 22px;
                font-size: 12px;
                white-space: nowrap;
                border-radius: 5px;
              "
              >複製</van-button
            >
          </template>
        </van-cell>
      </van-cell-group>

      <van-cell-group
        inset
        style="width: 100%; margin-bottom: 10px"
        v-if="currentOrder.bank_branch && currentOrder.bank_name != '抖音红包'"
      >
        <van-cell
          title="支行"
          :value="currentOrder.bank_branch"
          v-clipboard:copy="currentOrder.bank_branch"
          v-clipboard:success="onCopy"
        >
          <template #default>
            <p style="margin-right: 5px">
              {{ currentOrder.bank_branch }}
            </p>
          </template>
          <template #right-icon>
            <van-button
              size="normal"
              block
              plain
              style="
                background-color: #4065f0;
                width: 45px;
                color: white;
                height: 22px;
                font-size: 12px;
                white-space: nowrap;
                border-radius: 5px;
              "
              >複製</van-button
            >
          </template>
        </van-cell>
      </van-cell-group>
      <van-cell-group
        inset
        style="width: 100%; margin-bottom: 10px"
        v-if="String($store.state.selfData.df_card_info) != 0"
      >
        <van-cell
          title="选择出款卡"
          :value="currentOrder.bank_branch"
          @click="bankSelect = true"
          required
        >
          <template #default>
            <p style="margin-right: 5px">
              {{ currentBankCard }}
            </p>
          </template>
        </van-cell>
        <van-cell v-if="String($store.state.selfData.df_card_info) != 0">
          <template #title>
            <p style="color: red; font-size: 12px">
              如选择出款卡与实际出款卡不同，会有延迟增加余额或无法正常添加增加余额问题。
              <br />支付宝订单支付时 如有出现多数账号 请寻求客服人员后再行支付
            </p>
          </template>
        </van-cell>
      </van-cell-group>
      <van-cell-group
        inset
        style="width: 100%; margin-bottom: 10px"
        v-if="currentOrder.status == 0 || currentOrder.status == 5"
      >
        <van-cell v-if="String($store.state.selfData.df_card_info) != 0">
          <template #title>
            <p style="margin-bottom: 10px">
              文件上传 <span style="color: red">(上限6个)</span>
            </p>
            <div class="d-center">
              <van-uploader
                :after-read="beforeUpload"
                :max-size="8000 * 1024"
                @oversize="onOversize"
                v-model="currentOrder.files"
                :max-count="6"
              />
            </div>
          </template>
        </van-cell>
      </van-cell-group>
      <van-cell-group
        inset
        style="width: 100%"
        v-if="currentOrder.status == 0 || currentOrder.status == 5"
      >
        <van-field
          v-model="currentOrder.note"
          rows="2"
          autosize
          label="备注"
          type="textarea"
          placeholder="请输入备注"
        />
      </van-cell-group>
    </div>
    <div class="footBtnWrap">
      <van-button
        v-if="currentOrder.status == 0"
        @click="orderError"
        style="
          height: 40px;
          border: none;
          margin-right: 5px;
          border-radius: 5px;
          font-weight: bold;
        "
        size="normal"
        plain
        block
        class="errorBtn"
      >
        申请取消
      </van-button>
      <van-button
        v-if="currentOrder.status == 0 || currentOrder.status == 5"
        @click="checkOrder"
        style="
          height: 40px;
          margin-left: 5px;
          border-radius: 5px;
          font-weight: bold;
        "
        size="normal"
        block
        type="danger"
        color="#4065F0"
      >
        确认提交
      </van-button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import VueQrcode from "vue-qrcode";
import * as imageConversion from "image-conversion";
export default {
  components: { VueQrcode },
  data() {
    return {
      options: {
        target: "//localhost:3000/upload",
        testChunks: false,
      },
      currentOrder: {},
      setOrderModal: false,
      orderForm: {},
      cardList: [],
      status: ["待付款", "已完成", "已驳回", "待审核", "", "审核失败"],
      bankSelect: false,
      currentBankCard: "",
      currentBankCardId: "",
      orderErrorModal: false,
      note: "",
      errorFiles: [],
    };
  },
  methods: {
    orderError() {
      this.orderErrorModal = true;
    },
    onOversize() {
      this.$toast.fail("档案过大,最多可上传8MB");
    },
    goBack() {
      if (
        document.domain == "runn-f.test" ||
        document.domain == "store.rkzx921.com" ||
        document.domain == "119.91.195.29"
      ) {
        if (this.currentOrder.bank_name == "支付宝") {
          this.$router.push(`/ordersAlP?getMethod=${this.currentOrder.status}`);
        } else {
          this.$router.push(`/ordersP?getMethod=${this.currentOrder.status}`);
        }
      } else {
        this.$router.push(`/ordersP?getMethod=${this.currentOrder.status}`);
      }
    },

    async setErrorBefore(method, done) {
      if (method === "confirm") {
        if (!this.errorFiles || !this.errorFiles.length) {
          this.$toast.fail("请传送图片");
          done(false);
          return;
        }

        let vm = this;
        let formData = new FormData();
        if (!this.note) {
          this.$toast.fail("请输入原因");
          done(false);
          return;
        }
        formData.append("note", this.note);
        this.errorFiles.forEach((file) => {
          formData.append("files[]", file.file);
        });

        vm.$http
          .post(`/mobile/payments/${vm.currentOrder.order_id}/cancel`, formData)
          .then((res) => {
            if (res.data.code === 0) {
              vm.orderErrorModal = false;
              vm.$router.push("/ordersP");
              vm.$toast.success("完成");
            } else {
              // 可以在这里处理非成功的响应
            }
          })
          .catch((error) => {
            // 处理请求错误
            console.error("请求错误:", error);
            // 这里可以添加更多的错误处理逻辑
          });

        done(); // 根据您的逻辑调整 done() 的位置
      } else {
        done();
      }
    },
    beforeUpload(files) {
      console.log(files);
      this.$store.dispatch("setLoadding", true);
      let allFiles = Array.isArray(files) ? files : [files];
      return Promise.all(
        allFiles.map((file) => {
          if (file.file.type.indexOf("video") !== -1) {
            return Promise.resolve(file.file);
          }
          return imageConversion
            .compressAccurately(file.file, 1000)
            .then((res) => res)
            .catch((err) => {
              this.$toast.fail("请检查文件格式");
              return Promise.reject(err);
            });
        })
      ).finally(() => {
        this.$store.dispatch("setLoadding", false);
      });
    },
    // beforeUplo1ad(files) {
    //   this.$store.dispatch("setLoadding", true);
    //   let allFile = [];
    //   if (!Array.isArray(files)) {
    //     allFile = [files];
    //   } else {
    //     allFile = files;
    //   }
    //   return Promise.all(
    //     allFile.map((file) => {
    //       if (file.type.indexOf("video") != -1) {
    //         this.$store.dispatch("setLoadding", false);
    //         return file;
    //       }
    //       return new Promise((resolve, reject) => {
    //         imageConversion
    //           .compressAccurately(file, 1000)
    //           .then((res) => {
    //             resolve(res);
    //             this.$store.dispatch("setLoadding", false);
    //           })
    //           .catch((err) => {
    //             this.$toast.fail("请检查文件格式");
    //             this.$store.dispatch("setLoadding", false);
    //             reject(err);
    //           });
    //       });
    //     })
    //   );
    // },
    onConfirm(value) {
      this.currentBankCard = value.bank_name + value.bank_no;
      this.currentBankCardId = value.id;
      this.bankSelect = false;
    },
    onCopy() {
      this.$toast.success("已复制");
    },
    timeoutOrder() {},
    checkOrder() {
      if (!this.currentOrder.files || this.currentOrder.files.length == 0) {
        this.$toast.fail("请上传文件");
        return;
      }
      this.setOrderModal = true;
    },
    async setOrderBefore(method, done) {
      let vm = this;
      if (method == "confirm") {
        done(false);
        var formData = new FormData();
        if (this.currentOrder.note) {
          formData.append("note", this.currentOrder.note);
        }
        if (this.currentBankCardId) {
          formData.append("card_id", this.currentBankCardId);
        }

        this.currentOrder.files.forEach((e) => {
          formData.append("files[]", e.file);
        });
        let haveFile = false;
        for (let [key] of formData.entries()) {
          if (key == "files[]") {
            haveFile = true;
          }
        }
        if (!haveFile) {
          this.$toast.fail("请刷新页面后再重新操作");
          return;
        }
        vm.$http
          .post(
            `/mobile/payments/${vm.currentOrder.order_id}/check`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((res) => {
            if (res.data.code == 0) {
              vm.$toast.success("完成");
              vm.$router.push("/ordersP");
            }
          });
      } else {
        done();
      }
    },
  },
  computed: {
    ...mapState(["pOrders"]),
  },
  watch: {
    pOrders() {
      let order = JSON.parse(JSON.stringify(this.currentOrder));
      this.pOrders.orders.forEach((e) => {
        if (e.order_id == this.$route.params.id) [(this.currentOrder = e)];
      });
      this.$http
        .get(`/mobile/payments/${this.currentOrder.order_id}`)
        .then((res) => {
          Object.keys(res.data.data).forEach((e) => {
            order[e] = res.data.data[e];
          });
          this.currentOrder = order;
        });
    },
  },
  created() {
    this.$http
      .get(`/mobile/bankCard?type=0&page=${this.currentPage}`)
      .then((res) => {
        if (res.data.code == 0) {
          this.cardList = res.data.data;
        }
      });
    if (this.$route.params.check_amount) {
      this.currentOrder = this.$route.params;
    } else {
      this.currentOrder = this.$route.query;
    }
    this.$http
      .get(`/mobile/payments/${this.currentOrder.order_id}`)
      .then((res) => {
        let data = JSON.parse(JSON.stringify(this.currentOrder));
        Object.keys(res.data.data).forEach((e) => {
          data[e] = res.data.data[e];
        });
        this.currentOrder = data;
      });
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  // justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  width: 100%;
}

.contentWrap {
  padding: 20px;
  display: flex;
  padding-top: 0;
  flex-direction: column;
  align-items: center;
  background-color: #f1f5fe;
}

.footBtn {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  box-shadow: 5px 0px 0px rgba(#000000, 0.05);
  height: 60px;
  font-size: 14pt;
}

.dialogInfo {
  padding: 0 5px;
  color: rgb(219, 99, 101);
  font-size: 14px;
}

.footBtn {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.dialogInfo {
  padding: 0 5px;
  color: rgb(219, 99, 101);
  font-size: 14px;
}

.errorBtn {
  .van-button__content {
    color: #b8b6b7;
  }
}
.van-cell__title {
  span {
    font-weight: bold;
  }
}
.van-cell {
  padding-top: 15px;
  padding-bottom: 15px;
}
.footBtnWrap {
  width: 100%;
  display: flex;
}
</style>
